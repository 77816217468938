// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  local: false,
  environment: 'dev',
  apiRoot: 'https://api.homolog.receitadigital.com/api/',
  apiV1: 'https://api.homolog.receitadigital.com/v1',
  plusGApp: 'https://assine-stage.gosigner.com.br',
  envPlusSecretKey: '637e2ffee279a02ff6d1ccec224d01db',
  envPlusSecretKeyIS: 'a5e82ce673fe7007f96fb2c2b7fce055',
  envKeySpecialty: 'fr1MzCU8RC3YPIX8prW9EalrEfoYiWqi8afdVbDk',
  envKeyMedicament: 'wBwNQLTg2t3bTFWjNhgZ01iFEnJC3Mu05D4G23IE',
  envAdminApiKey: 'RfMlZ8UB8GiRkdfeN4c5COCL58ldtauB',
  debounceTime: 300,
  maintenance: false,
  template: '',
  versionCheckURL: 'https://homolog.receitadigital.com/version.json',
  tempoDeValidadeDaSessao: 60,
  appRedirectV2Enabled: true,
  appV2UrlValidationDocument: 'https://staging2.receitadigital.com/d/v',
  envCmSereneApiKey: '005A5I4DF8JGA39GDI3G3C159GEE4E7C'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
